// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chessable-js": () => import("./../../../src/pages/chessable.js" /* webpackChunkName: "component---src-pages-chessable-js" */),
  "component---src-pages-code-23-js": () => import("./../../../src/pages/code23.js" /* webpackChunkName: "component---src-pages-code-23-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orchest-js": () => import("./../../../src/pages/orchest.js" /* webpackChunkName: "component---src-pages-orchest-js" */)
}

